<template>
	<el-dialog title="添加物流网点" top="1vh" width="1080px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="90px">
			<div class="big_tit" style="margin:0">物流公司/物流园</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="物流公司" style="width:388px">
					<el-input class="el_inner_width" v-model="logistice_company_choose.name" disabled>
						<el-button @click="logistice_company_choose_open()" slot="append">选择</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="物流园" style="width:588px">
					<el-input class="el_inner_width" v-model="logistice_park_choose.name" disabled>
						<el-button @click="logistice_park_choose_open()" slot="append">选择</el-button>
					</el-input>
				</el-form-item>
			</div>
			

			<div class="big_tit">网点信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.link_man" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.link_tel" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="网点名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.name" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="备注" style="width:588px">
					<el-input class="el_inner_width" v-model="form.mark" clearable></el-input>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">确定添加</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>

		<!-- 物流公司选择弹出层 -->
		<logisticsCompanyChoose 
			:is_show="logistice_company_choose.is_show" 
			@choosed="logistice_company_choosed" 
		></logisticsCompanyChoose>

		<!-- 物流园区选择弹出层 -->
		<logisticsParkChoose 
			:is_show="logistice_park_choose.is_show" 
			@choosed="logistice_park_choosed" 
		></logisticsParkChoose>

	</el-dialog>
</template>
<script>
	import logisticsCompanyChoose from '../company/choose.vue'
	import logisticsParkChoose from '../park/choose.vue'
	export default {
		components:{
			logisticsCompanyChoose,
			logisticsParkChoose,
		},
		props:{
			is_show:Number,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					name:'',
					link_man:'',
					link_tel:'',
					mark:'',
				},

				//物流公司选择
				logistice_company_choose:{
					is_show:0,
					name:'',
					logistics_company_num:'',
				},

				//物流园区选择
				logistice_park_choose:{
					is_show:0,
					name:'',
					logistics_park_num:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

					//初始化
					this.init();
					
				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//提交
			sub(){
				
				if(!this.logistice_company_choose.logistics_company_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少物流公司信息'
					});
					return;
				}

				if(!this.logistice_park_choose.logistics_park_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少物流园信息'
					});
					return;
				}

				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少网点名称'
					});
					return;
				}

				//提交数据
				this.$my.net.req({
					data:{
						mod:'logistics',
						ctr:'node_add_by_admin',
						logistics_company_num:this.logistice_company_choose.logistics_company_num,
						logistics_park_num:this.logistice_park_choose.logistics_park_num,
						...this.form,
					},
					callback:(data)=>{

						//添加成功
						this.$my.other.confirm({
							content:"添加成功,是否前往列表页?",
							confirm:()=>{

								//通知
								this.$emit("added");
							}
						});
					}
				});
			},

			//打开物流公司选择界面
			logistice_company_choose_open(type){
				
				//打开界面
				this.logistice_company_choose.is_show++
			},
			logistice_company_choosed(obj){

				//置入数据
				this.logistice_company_choose.name=obj.name
				this.logistice_company_choose.logistics_company_num=obj.logistics_company_num

				//关闭弹出层
				this.logistice_company_choose.is_show=0
			},

			//打开物流园区选择界面
			logistice_park_choose_open(type){
				
				//打开界面
				this.logistice_park_choose.is_show++
			},
			logistice_park_choosed(obj){

				//置入数据
				this.logistice_park_choose.name=obj.name
				this.logistice_park_choose.logistics_park_num=obj.logistics_park_num

				//关闭弹出层
				this.logistice_park_choose.is_show=0
			},

			//初始化
			init(){

				//物流公司选择
				this.logistice_company_choose.is_show=0
				this.logistice_company_choose.name=''
				this.logistice_company_choose.logistics_company_num=''

				//物流园区选择
				this.logistice_park_choose.is_show=0
				this.logistice_park_choose.name=''
				this.logistice_park_choose.logistics_park_num=''

				//表单
				this.form.name=''
				this.form.link_man=''
				this.form.link_tel=''
				this.form.mark=''
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>